import { HttpClient } from '@angular/common/http';
import { io, Socket } from 'socket.io-client';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Notifications } from './notifications.model';

const BACKEND_URL_NOTIFICATIONS = environment.apiBaseUrl + "/notifications";
const BACKEND_URL_NOTIFIES = environment.apiBaseUrl + "/notifies";
const BACKEND_URL_STATISTICS = environment.apiBaseUrl + "/statistics";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  // Set variables
  private notifications: Notifications[] = [];
  private notificationsUpdated = new Subject<{ notifications: Notifications[], unseenNotificationsCount: number, notificationsCount: number }>();
  notificationsCount = new BehaviorSubject(null);

  private socket: Socket;
  private isConnected = false;

  constructor(private http: HttpClient, private router: Router) { }

  // Connect to socket 
  connect(userId: string): void {
    this.socket = io(environment.websocketUrl, {
      query: { userId }
    });

    this.socket.on('connect', () => {
      console.log('Connected to WebSocket');
      this.isConnected = true;
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket');
      this.isConnected = false;
    });

    this.socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    // Debugging: Listen for all events
    this.socket.onAny((event, ...args) => {
      console.log(`Received event: ${event}`, args);
    });
  }

  // Get connection status
  getConnectionStatus(): boolean {
    return this.isConnected;
  }

  // Emit message
  emit(eventName: string, data: any): void {
    if (!this.isConnected) {
      throw new Error('Socket is not connected');
    }
    this.socket.emit(eventName, data);
  }

  // Recieve message
  on(eventName: string, callback: (data: any) => void): void {
    this.socket.on(eventName, callback);
  }

  // Get all notifications
  getNotifications(dataPerPage: number, currentPage: number, userId: string) {
    const queryParams = `?pagesize=${dataPerPage}&page=${currentPage}`;
    this.http.get<{ message: string, notifications: any, unseenNotificationsCounter: number, maxNotifications: number }>(BACKEND_URL_NOTIFICATIONS + '/user/' + userId + queryParams)
      .pipe(map((notificationsData) => {
        return {
          notifications: notificationsData.notifications.map((notification) => {
            var firstChar = notification.sentBy.firstName.charAt(0);
            var secondChar = notification.sentBy.lastName.charAt(0);
            var shortName = firstChar + secondChar;
            notification.sentBy['shortName'] = shortName;
            return {
              id: notification._id,
              title: notification.title,
              body: notification.body,
              entityId: notification.entityId,
              entity: notification.entity,
              type: notification.type,
              notify: notification.notify,
              sentBy: notification.sentBy,
              seenBy: notification.seenBy,
              seen: notification.seen,
              read: notification.read,
              sentTo: notification.sentTo,
              redirectTo: notification.redirectTo,
              sentAt: notification.sentAt,
              sentTime: notification.sentTime
            }
          }),
          unseenNotificationsCounter: notificationsData.unseenNotificationsCounter,
          maxNotifications: notificationsData.maxNotifications
        };
      }))
      .subscribe((transformedNotificationsData) => {
        if (currentPage === 1) {
          this.notifications = transformedNotificationsData.notifications;
        } else {
          this.notifications = [...this.notifications, ...transformedNotificationsData.notifications];
        }
        this.notificationsUpdated.next({
          notifications: [...this.notifications],
          unseenNotificationsCount: transformedNotificationsData.unseenNotificationsCounter,
          notificationsCount: transformedNotificationsData.maxNotifications
        });
      });
  }

  // Listen notifications update
  getNotificationsUpdatedListener() {
    return this.notificationsUpdated.asObservable();
  }

  // Get notifies list
  getNotifiesList() {
    return this.http.get<{ message: string, notifies: any }>(BACKEND_URL_NOTIFIES);
  }

  // Get notifications counter
  getNotificationsCounter(userId: string) {
    this.http.get<{ message: string, notificationsCounter: any }>(BACKEND_URL_STATISTICS + '/counter/notifications/' + userId)
      .subscribe((resultData) => {
        this.notificationsCount.next(resultData.notificationsCounter);
      });
  }

  // Update notifications as seen
  updateNotificationsSeen(seenData: any) {
    return this.http.put<{ message: string }>(BACKEND_URL_NOTIFICATIONS + '/seen/user/' + seenData.userId, seenData);
  }

  // Update notifications as read
  updateNotificationsRead(readData: any) {
    return this.http.put<{ message: string }>(BACKEND_URL_NOTIFICATIONS + '/read/user/' + readData.userId, readData);
  }

  // Update all notifications as read
  updateAllNotificationsRead(readData: any) {
    return this.http.put<{ message: string }>(BACKEND_URL_NOTIFICATIONS + '/markall/read/user/' + readData.userId, readData);
  }

  // Delete notification
  deleteNotification(removeData: any) {
    return this.http.put<{ message: string }>(BACKEND_URL_NOTIFICATIONS + '/remove/user/' + removeData.userId, removeData);
  }
}
