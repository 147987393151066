import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccessService } from 'src/app/access/access.service';

@Injectable()
export class AccessInterceptor implements HttpInterceptor {

    constructor(public accessService: AccessService) { }

    // Set token to header for every http request
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken = this.accessService.getToken();
        let secretKey = req.headers.get("Authorization");

        if (secretKey && !authToken) {
            const token = secretKey.split(" ")[1];
            const authRequest = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + token)
            })
            return next.handle(authRequest);
        } else {
            const authRequest = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + authToken)
            })
            return next.handle(authRequest);
        }
    }
}