import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Email, ResetPassword, Signin, Signup } from './access.model';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

const BACKEND_URL_USER = environment.apiBaseUrl + "/users";

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  // Set variables
  private token: string;
  private userId: string;
  private roleId: string;
  private permissions: any;
  private isAuthenticated = false;
  private authStatusListner = new Subject<boolean>();
  private tokenTimer: any;
  private user: any;
  private userUpdated = new Subject<{ user: any }>();

  constructor(private http: HttpClient, private router: Router) { }

  // Get authenticated status
  getIsAuth() {
    return this.isAuthenticated;
  }

  // Get token
  getToken() {
    return this.token;
  }

  // Listen auth status
  getAuthStatusListner() {
    return this.authStatusListner.asObservable();
  }

  // Get user id
  getUserId() {
    return this.userId;
  }

  // Get permissions ids
  getPermissions() {
    return this.permissions;
  }

  // Login to app
  access(userData: Signin) {
    const authData: Signin = {
      email: userData.email,
      password: userData.password
    }
    this.http.post<{ message: string, token: string, expiresIn: number, expirationDate: string, userId: string, roleId: string, permissions: any }>(BACKEND_URL_USER + '/access', authData)
      .subscribe((responseData) => {
        const token = responseData.token;
        this.token = token;
        // Check if token exist
        if (token) {
          // Set auth data and redirect to app
          const expireInDuration = responseData.expiresIn;
          this.setAuthTimer(expireInDuration);
          this.isAuthenticated = true;
          this.userId = responseData.userId;
          this.roleId = responseData.roleId;
          this.permissions = responseData.permissions;
          this.authStatusListner.next(true);

          const expirationDate = responseData.expirationDate;
          this.saveAuthData(token, expirationDate, this.userId, this.roleId, this.permissions);
          this.router.navigate(["/feed"]);
        }
      }, (error: any) => {
        this.authStatusListner.next(false);
        console.log(error);
      });
  }

  // Set timer for token expiry, Logout after token expire
  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logOut();
    }, duration * 1000);
  }

  // Set to local storage
  private saveAuthData(token: string, expirationDate: string, userId: string, roleId: string, permissions: any) {
    localStorage.setItem("community_user_token", token);
    localStorage.setItem("community_user_token_expiration", expirationDate);
    localStorage.setItem("community_user_id", userId);
    localStorage.setItem("community_role_id", roleId);
    localStorage.setItem("community_permissions", JSON.stringify(permissions));
  }

  // Logout from app
  logOut() {
    this.isAuthenticated = false;
    this.authStatusListner.next(false);
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(["/access"]);
  }

  // Clear authentication data
  private clearAuthData() {
    if (this.userId && this.token && this.roleId) {
      this.token = null;
      this.userId = null;
      this.roleId = null;
      this.permissions = null;
      localStorage.removeItem("community_user_token");
      localStorage.removeItem("community_user_token_expiration");
      localStorage.removeItem("community_user_id");
      localStorage.removeItem("community_role_id");
      localStorage.removeItem("community_permissions");
      localStorage.removeItem("community_post_type");
    } else {
      localStorage.removeItem("community_user_token");
      localStorage.removeItem("community_user_token_expiration");
      localStorage.removeItem("community_user_id");
      localStorage.removeItem("community_role_id");
      localStorage.removeItem("community_permissions");
      localStorage.removeItem("community_post_type");
    }
  }

  // Check auto authentication on every route load
  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const expiresIn = Math.round(moment(authInformation.expirationDate).valueOf() / 1000) - Math.round(moment().valueOf() / 1000);
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.userId = authInformation.userId;
      this.roleId = authInformation.roleId;
      this.permissions = authInformation.permissions;
      this.setAuthTimer(expiresIn);
      this.authStatusListner.next(true);
    }
  }

  // Get auth data
  private getAuthData() {
    const token = localStorage.getItem("community_user_token");
    const expirationDate = localStorage.getItem("community_user_token_expiration");
    const userId = localStorage.getItem("community_user_id");
    const roleId = localStorage.getItem("community_role_id");
    const permissions = JSON.parse(localStorage.getItem("community_permissions"));
    if (!token || !expirationDate || !userId || !roleId || !permissions) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      roleId: roleId,
      permissions: permissions,
    }
  }

  // Check for local data
  localDataExists() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return false;
    }
    return true;
  }

  // Get user details
  getUser(id: string) {
    this.http.get<{ message: string, user: any }>(BACKEND_URL_USER + '/' + id)
      .pipe(map((userData) => {
        var firstChar = userData.user.firstName.charAt(0);
        var secondChar = userData.user.lastName.charAt(0);
        var shortName = firstChar + secondChar;
        return {
          user: {
            id: userData.user._id,
            username: userData.user.username,
            firstName: userData.user.firstName,
            lastName: userData.user.lastName,
            headline: userData.user.headline,
            email: userData.user.email,
            emailVerified: userData.user.emailVerified,
            countryCode: userData.user.countryCode,
            mobile: userData.user.mobile,
            mobileVerified: userData.user.mobileVerified,
            image: userData.user.image,
            cover: userData.user.cover,
            shortName: shortName.toUpperCase(),
            bio: userData.user.bio,
            birthDate: userData.user.birthDate,
            address: userData.user.address,
            accountType: userData.user.accountType,
            groupsCounter: userData.user.groupsCounter,
            notifyFor: userData.user.notifyFor,
            notificationsCounter: userData.user.notificationsCounter,
            guestProfile: false,
            pushNotification: userData.user.pushNotification,
            pushNotificationToken: userData.user.pushNotificationToken,
            isActive: userData.user.isActive
          }
        }
      })).subscribe((transformedUserData) => {
        this.user = transformedUserData.user;
        this.userUpdated.next({
          user: this.user
        });
      }, (error: any) => {
        console.log(error);
      });
  }

  // List user update
  getUserUpdatedListener() {
    return this.userUpdated.asObservable();
  }

  // Member signup
  signUp(signupData: Signup) {
    return this.http.post<{ message: string }>(BACKEND_URL_USER + '/registration', signupData);
  }

  // Forgot password
  forgotPassword(email: Email) {
    return this.http.post<{ message: string }>(BACKEND_URL_USER + '/forgot-password', email);
  }

  // Reset password
  resetPassword(resetData: ResetPassword) {
    return this.http.post<{ message: string }>(BACKEND_URL_USER + '/reset-password', resetData);
  }

  // Linkedin signin
  linkedinSignin(signinData: any) {
    return this.http.post<{ message: string, linkedin: boolean, user: any }>(BACKEND_URL_USER + '/linkedin/access', signinData);
  }

  // Check permission
  isPermitted(permissionName: any) {
    let exists = false;
    let permissions = [];
    if (this.getPermissions() === null) {
      permissions = [];
    } else {
      permissions = this.getPermissions();
    }

    permissions.forEach(permission => {
      if (permission.permission === permissionName) {
        exists = true;
      }
    });
    return exists;
  }

  // Default post type
  setPostType(type: string) {
    localStorage.setItem("community_post_type", type);
  }
  getPostType() {
    return localStorage.getItem("community_post_type");
  }
  removePostType() {
    localStorage.removeItem("community_post_type");
  }

}
