import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { SnackbarService } from './snackbar.service';
import { map, mergeMap, mergeMapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  // Set variables
  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging, public snackbarService: SnackbarService) { }

  // Request for push notification permissions
  requestPermission(): Observable<any> {
    return this.angularFireMessaging.requestToken.pipe(mergeMapTo(this.angularFireMessaging.tokenChanges));
  }

  // Delete current token of push notifications
  deleteToken(): Observable<any> {
    return this.angularFireMessaging.getToken.pipe(mergeMap(token => this.angularFireMessaging.deleteToken(token)));
  }

  // Get push notification message
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      this.currentMessage.next(payload);
    });
  }
}