// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiBaseUrl: 'http://localhost:3001/api',
  apiBaseUrl: 'https://beta.paritycommunities.com/api',
  // websiteUrl: 'http://localhost:4300',
  websiteUrl: 'https://beta.paritycommunities.com',
  version: '5.0.0',
  linkedin: {
    clientId: "86mi0gj46ekn4c",
    state: "DCEeFEf05A53sdfKef924",
  },
  firebase: {
    apiKey: "AIzaSyDcKxaytkw3wtRRnj_UOwnZ5vbPC8VKVzM",
    authDomain: "paritycommunities-ad9f5.firebaseapp.com",
    projectId: "paritycommunities-ad9f5",
    storageBucket: "paritycommunities-ad9f5.appspot.com",
    messagingSenderId: "328429827264",
    appId: "1:328429827264:web:1986099152a46bf4c3e925",
    measurementId: "G-VRY4XJHHX2"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
