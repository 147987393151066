import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { AccessGuard } from './shared/guard/access.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import("./home/home.module").then(m => m.HomeModule)
  },
  {
    path: 'access',
    loadChildren: () => import("./access/access.module").then(m => m.AccessModule)
  },
  {
    path: 'share',
    loadChildren: () => import("./share-contents/share-contents.module").then(m => m.ShareContentsModule)
  },
  {
    path: '',
    canActivate: [AccessGuard],
    loadChildren: () => import("./private/layout/layout.module").then(m => m.LayoutModule)
  },
  {
    path: 'error',
    component: ServerErrorComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
